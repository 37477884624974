<template>
  <Layout>
    <div class="app-container">
      <div style="margin-bottom: 44px;">
        <el-button
          type="primary"
          @click="$router.push('/TreasureBox/management')"
        >
          管理活动信息
        </el-button>
        <el-button
          type="primary"
          @click="$router.push('/TreasureBox/rule-edit')"
        >
          管理活动规则
        </el-button>
      </div>
      <div class="header-bar">
        <span class="title">活动分析</span>
        <div>
          <el-date-picker
            v-model="date"
            type="daterange"
            format="yyyy-MM-dd"
            unlink-panels
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="pickerOptions"
            @change="queryAll"
          />
        </div>
      </div>
      <div
        v-loading="bannerDataLoading"
        class="banner"
      >
        <div
          v-for="(item, index) in bannerList"
          :key="index"
          class="banner-list"
        >
          <div class="name">
            {{ item.name }}
          </div>
          <div class="number">
            {{ typeof bannerData[item.field] === 'number' ? bannerData[item.field] : '--' }}
          </div>
        </div>
      </div>
      <div class="header-bar">
        <span class="title">订单 - 金币/VIP</span>
      </div>
      <el-table
        v-loading="tableDataLoading"
        :data="tableData"
        border
      >
        <el-table-column
          prop="title"
        >
          <template v-slot="{row}">
            {{ statisticsTypeMap[row.statisticsType] || '----' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="detail.gold_200"
          label="金币 - 200"
        >
          <template v-slot="{row}">
            {{ row.detail.gold_200 | formatTable(row.statisticsType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="detail.gold_500"
          label="金币 - 500"
        >
          <template v-slot="{row}">
            {{ row.detail.gold_500 | formatTable(row.statisticsType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="detail.gold_1000"
          label="金币 - 1000"
        >
          <template v-slot="{row}">
            {{ row.detail.gold_1000 | formatTable(row.statisticsType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="detail.gold_2000"
          label="金币 - 2000"
        >
          <template v-slot="{row}">
            {{ row.detail.gold_2000 | formatTable(row.statisticsType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="detail.gold_5000"
          label="金币 - 5000"
        >
          <template v-slot="{row}">
            {{ row.detail.gold_5000 | formatTable(row.statisticsType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="detail.vip_1"
          label="VIP-1月"
        >
          <template v-slot="{row}">
            {{ row.detail.vip_1 | formatTable(row.statisticsType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="detail.vip_3"
          label="VIP-3月"
        >
          <template v-slot="{row}">
            {{ row.detail.vip_3 | formatTable(row.statisticsType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="detail.vip_12"
          label="VIP-12月"
        >
          <template v-slot="{row}">
            {{ row.detail.vip_12 | formatTable(row.statisticsType) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </Layout>
</template>

<script>
import {
  getStatisticalBrief,
  getBoxOrderStatistical
} from '@/api/TreasureBox'

const dateNow = () => {
  const start = new Date(new Date(new Date().toLocaleDateString()).getTime())
  const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1000)
  return [start, end]
}

const statisticsTypeMap = {
  1: '订单总数',
  2: '订单客户数',
  3: '订单占比'
}
export default {
  name: 'Dashboard',
  filters: {
    formatTable (value, type) {
      if (type === 3) return value + '%'
      return value
    }
  },
  data () {
    return {
      date: '',
      statisticsTypeMap,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              const [start, end] = dateNow()
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const [start, end] = dateNow()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '上周',
            onClick (picker) {
              const [start, end] = dateNow()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '上个月',
            onClick (picker) {
              const [start, end] = dateNow()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      bannerList: [
        { name: '浏览人数', field: 'visitorsCount' },
        { name: '浏览人次', field: 'visitsCount' },
        { name: '开宝箱人次', field: 'openBoxCount' },
        { name: '开宝箱人数', field: 'openBoxPeopleCount' },
        { name: '兑换次数', field: 'exchangesCount' },
        { name: '领取次数', field: 'receiptsCount' }
      ],
      bannerData: {},
      bannerDataLoading: false,
      tableData: [],
      tableDataLoading: false
    }
  },
  created () {
    const [start, end] = dateNow()
    start.setTime(start.getTime() - 3600 * 1000 * 24)
    end.setTime(end.getTime() - 3600 * 1000 * 24)
    this.date = [start.getTime(), end.getTime()]
    this.queryAll()
  },
  methods: {
    queryAll () {
      this.queryData()
      this.queryList()
    },
    queryData () {
      const [startTime, endTime] = this.date
      this.bannerDataLoading = true
      getStatisticalBrief({
        startTime: Math.floor(startTime / 1000),
        endTime: Math.floor(endTime / 1000)
      })
        .then(res => {
          if (res.code === 200) {
            this.bannerData = res.data
          }
        })
        .finally(() => {
          this.bannerDataLoading = false
        })
    },
    queryList () {
      const [startTime, endTime] = this.date
      this.tableDataLoading = true
      getBoxOrderStatistical({
        startTime: Math.floor(startTime / 1000),
        endTime: Math.floor(endTime / 1000)
      })
        .then(res => {
          if (res.code === 200) {
            this.tableData = res.data.list
          }
        })
        .finally(() => {
          this.tableDataLoading = false
        })
    }
  }
}
</script>

<style scoped lang="less">
  .header-bar{
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    .title{
      font-weight: bolder;
    }
  }
  .banner{
    &:after{
      content: '';
      display: block;
      clear: both;
    }
    .banner-list{
      float: left;
      background: #409eff;
      color: white;
      margin-right: 30px;
      padding:14px 20px;
      min-width: 120px;
      border-radius: 4px;
      margin-bottom: 20px;
      .name{
        font-size: 14px;
        margin-bottom: 4px;
      }
      .number{
        font-size: 18px;
      }
    }
  }
</style>
